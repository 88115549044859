import * as React from "react"
import Layout from "../components/common/layout"
import { Separator } from "../components/common/styledComponentsHelper"

const AGBHeadlineStyle = "text-uppercase text-bold fw-bold"

export const Head = () => (
  <>
    <title>AGB | Fachhandwerk360</title>
    <meta
      name="facebook-domain-verification"
      content="xooo742lkxtkqa24ish1wyf2ppfc4e"
    />
  </>
)

const AGB = () => (
  <Layout>
    <div style={{ marginTop: "85px" }}>
      <Separator />
      <div className="container">
        <h1 className={AGBHeadlineStyle}>
          Allgemeine Geschäftsbedingungen (AGB) der Fachhandwerk360 Dreier &
          Herber GbR Stand: 2020
        </h1>
        <p>
          Fachhandwerk360 Dreier & Herber GbR <br />
          Rheinstraße 28 <br />
          55432 Niederburg
        </p>
        <p>vertreten durch die Gesellschafter:</p>
        <p>
          Rainer Dreier,
          <br />
          Martin Herber
        </p>
        <p>
          DIE VON FACHHANDWERK360 Dreier & Herber GbR ANGEBOTENEN PRODUKTE
          RICHTEN SICH AN UNTERNEHMER, DIE IHREN WOHN- ODER GESCHÄFTSSITZ IN DEN
          NACHFOLGENDEN LÄNDERN HABEN („Vertragsge­biet“):
        </p>
        <p>– Bundesrepublik Deutschland</p>
        <p>
          Weitere Länder sind geplant und folgen in Kürze. Fachhandwerk360
          Dreier & Herber GbR übernimmt keine Haftung und Gewähr­leistung für
          eine Nutzung der Services außerhalb des Ver­tragsgebietes.
        </p>
        <h2 className={AGBHeadlineStyle}>§ 1 Anwendungsbereich</h2>
        <p>
          (1) Fachhandwerk360 Dreier & Herber GbR, Rheinstraße 28, 55432
          Niederburg, (im Folgenden „FHW360“) bietet ihre Leistungen nach den
          folgenden Allgemeinen Geschäftsbedingungen an. Das Angebot richtet
          sich ausschließlich an Unternehmen. Abweichenden Allgemeinen
          Geschäftsbedingungen des Kunden wird widersprochen. Die Allgemeinen
          Geschäftsbedingungen und etwaige Zusatzbedingungen finden
          ausschließlich im unternehmerischen Geschäftsverkehr Anwendung.
        </p>
        <p>
          (2) Bestehen für einzelne Angebote und Dienste von FHW360
          Zusatzbedingungen, so gehen diese den Allgemeinen Geschäftsbedingungen
          vor, soweit in den Zusatzbedingungen nicht ausdrücklich die
          vollständige oder teilweise Geltung dieser Allgemeinen
          Geschäftsbedingungen bestimmt wird.
        </p>
        <p>
          (3) Änderungen der Allgemeinen Geschäftsbedingungen und sonstiger
          Vertragsbedingungen, die ein zwischen FHW360 und dem Kunden
          bestehendes Dauerschuldverhältnis betreffen (z.B. Webhosting, SaaS),
          werden dem Kunden spätestens einen Monat vor deren Inkrafttreten
          angezeigt. Bleibt die Änderung vom Kunden unwidersprochen oder nutzt
          dieser trotz Widerspruchs auch nach Inkrafttreten der neuen
          Geschäftsbedingungen die Dienste von FHW360 weiter, so gelten die
          neuen Bedingungen als vereinbart. Im anderen Falle wird das
          Vertragsverhältnis mit Inkrafttreten der neuen Bedingungen beendet,
          ohne dass es einer Kündigung durch FHW360 bedürfte; andere Leistungen
          und Vertragsverhältnisse zwischen den Parteien bleiben davon
          unberührt.
        </p>
        <h2 className={AGBHeadlineStyle}>
          § 2 Angebote, Vertragsschluss, Leistungsumfang
        </h2>
        <p>
          (1) Angebote, Preislisten und sonstigen Werbeunterlagen sind
          freibleibend und unverbindlich. Damit ist FHW360 im Falle der
          Nichtverfügbarkeit nicht zur Leistung verpflichtet. Ein
          Vertragsabschluss und damit eine vertragliche Bindung über die
          einzelnen Leistungen kommt jedoch dann zustande, wenn FHW360 das
          Angebot ausdrücklich durch eine Auftragsbestätigung oder durch
          schlüssiges Handeln annimmt.
        </p>
        <p>
          (2) FHW360 erbringt seine Dienstleistungen nach den Wünschen und
          Angaben des Kunden, wie sie insbesondere aus dem vom Kunden
          ausgefüllten Bestellformular, einem Leistungsschein und/oder sonstigen
          konkreten schriftlichen Vereinbarungen bei Vertragsschluss
          hervorgehen.
        </p>
        <p>
          (3) Der Kunde ist verpflichtet, rechtzeitig vor Ausführung des
          Auftrages alle für die Umsetzung des Auftrages erforderlichen Daten
          zur Verfügung zu stellen.
        </p>
        <p>
          (4) Installation, Einweisung und Schulung gehören nur zu den
          Leistungspflichten von FHW360, wenn dies vereinbart ist.
        </p>
        <p>
          (3) Änderungs- und Erweiterungswünsche muss FHW360 nur
          berücksichtigen, wenn sie aus technischen Gründen erforderlich sind,
          um den Vertragszweck zu erreichen. Bei einer wesentlichen Änderung der
          vertraglichen Pflichten von FHW360 zum Zweck der Anpassung an die
          Belange des Kunden kann FHW360 dem Kunden den erforderlichen
          Mehraufwand in Rechnung stellen. Dies gilt auch für eine umfangreiche
          Prüfung, ob und zu welchen Bedingungen die Änderung oder Erweiterung
          durchführbar ist, soweit FHW360 in Textform darauf hingewiesen hat.
        </p>
        <p>
          (4) Ist die Erbringung in Teillieferungen möglich, so ist FHW360 zu
          Teillieferungen berechtigt.
        </p>
        <p>
          (5) Bei kostenfreien Leistungen hat der Kunde keinen Anspruch auf
          Weiterführung oder Wiederholung der Leistung.
        </p>
        <h2 className={AGBHeadlineStyle}>§ 3 Preise und Zahlung</h2>
        <p>
          (1) Es gelten die Listenpreise im Zeitpunkt der Leistung. Festpreise
          gelten nur dann, wenn dies im Einzelfall vereinbart wurde.
          Teilzahlungen und Abschlagszahlungen sind nur möglich, soweit dies
          vertraglich vereinbart wurde.
        </p>
        <p>
          (2) Versandkosten, Installation, Schulung und sonstige Nebenleistungen
          sind im Preis nicht inbegriffen, soweit keine anders lautende
          Vereinbarung getroffen wurde.
        </p>
        <p>
          (3) Zusatzleistungen, die nicht in dem vom Kunden ausgefüllten
          Bestellformular, einem Leistungsschein und/oder sonstigen konkreten
          schriftlichen Vereinbarungen bei Vertragsschluss enthalten sind, sind
          gesondert zu vergüten. Dies gilt insbesondere für Mehraufwand infolge
        </p>
        <p>
          a) des Vorlegens von Daten in nicht digitalisierter Form,
          <br />
          b) von notwendiger und zumutbarer Inanspruchnahme von Leistungen
          Dritter,
          <br />
          c) von Aufwand für Lizenzmanagement sowie
          <br />
          d) in Auftrag gegebener Test-, Recherchedienstleistungen und
          rechtlichen Prüfungen.
          <br />
        </p>
        <p>
          (4) Soweit nicht anders vereinbart, sind die Rechnungen von FHW360 14
          Tage nach Rechnungsstellung ohne Abzug zahlbar.
        </p>
        <p>
          (5) Eine Zahlung gilt erst dann als erfolgt, wenn FHW360 über den
          Betrag verfügen kann. Im Falle von Schecks gilt die Zahlung erst als
          erfolgt, wenn der Scheck eingelöst wird.
        </p>
        <p>
          (6) Gerät der Kunde in Verzug, so werden ihm von dem betreffenden
          Zeitpunkt an Zinsen in Höhe von 8 Prozentpunkten über dem
          Basiszinssatz berechnet. Die Geltendmachung eines höheren
          Verzögerungsschadens bleibt FHW360 vorbehalten.
        </p>
        <p>
          (7) Der Kunde muss damit rechnen, dass FHW360 Zahlungen zunächst auf
          ältere Schulden anrechnet. Sind bereits Kosten der Rechtsverfolgung
          wie Mahnkosten entstanden, so kann FHW360 Zahlungen des Kunden
          zunächst auf diese Kosten, dann auf die Zinsen und zuletzt auf die
          Hauptleistung anrechnen.
        </p>
        <p>
          (8) Wenn FHW360 Umstände bekannt werden, welche die Kreditwürdigkeit
          des Kunden objektiv in Frage stellen, insbesondere der Kunde seine
          Zahlungen einstellt oder ein Scheck in Ermangelung ausreichender
          Deckung zurückgegeben wird, ist FHW360 berechtigt, die gesamte
          Restschuld fällig zu stellen, auch wenn FHW360 Schecks angenommen hat.
          FHW360 ist in diesem Falle außerdem berechtigt, Vorauszahlungen oder
          Sicherheitsleistung zu verlangen.
        </p>
        <h2 className={AGBHeadlineStyle}>
          § 4 Aufrechnung, Zurückbehaltung und Abtretung
        </h2>
        <p>
          (1) Der Kunde ist zur Aufrechnung, Zurückbehaltung oder Minderung,
          auch wenn Mängelrügen oder Gegenansprüche geltend gemacht werden, nur
          berechtigt, wenn die Gegenansprüche rechtskräftig festgestellt wurden
          oder unstreitig sind. Zur Zurückbehaltung ist der Kunde jedoch ohne
          die weiteren Voraussetzungen aus Satz 1 auch dann berechtigt, wenn das
          Zurückbehaltungsrecht wegen Gegenansprüchen aus demselben
          Vertragsverhältnis geltend gemacht wird.
        </p>
        <p>
          (2) Außer im Bereich des § 354a HGB kann der Kunde Ansprüche aus dem
          Vertrag nur mit vorheriger schriftlicher Zustimmung von FHW360 an
          Dritte abtreten.
        </p>
        <h2 className={AGBHeadlineStyle}>§ 5 Eigentumsvorbehalt</h2>
        <p>
          (1) Bis zur Erfüllung aller Forderungen (einschließlich sämtlicher
          Saldoforderungen aus Kontokorrent), die FHW360 gleich aus welchem
          Rechtsgrund gegen den Kunden zustehen, werden FHW360 die folgenden
          Sicherheiten gewährt, die FHW360 auf Verlangen nach eigener Wahl
          freigeben wird, soweit ihr Wert die Forderungen nachhaltig um mehr als
          20% übersteigt.
        </p>
        <p>
          (2) Gelieferte Ware bleibt Eigentum von FHW360. Verarbeitung oder
          Umbildung erfolgen stets für FHW360 als Hersteller, jedoch ohne
          Verpflichtung für FHW360. Erlischt das Eigentum von FHW360 durch
          Verbindung, so wird bereits jetzt vereinbart, dass das Eigentum von
          FHW360 an der einheitlichen Sache wertanteilmäßig (Rechnungswert) auf
          FHW360 übergeht. Der Kunde verwahrt das Eigentum von FHW360
          unentgeltlich. Ware, an der FHW360 Eigentum zusteht, wird im Folgenden
          als Vorbehaltsware bezeichnet.
        </p>
        <p>
          (3) Der Kunde ist berechtigt, die Vorbehaltsware im ordnungsgemäßen
          Geschäftsverkehr zu verarbeiten und zu veräußern, solange er nicht in
          Verzug ist. Zum ordnungsgemäßen Geschäftsverkehr gehören solche
          Maßnahmen nicht, die gegen andere Rechte von FHW360, insbesondere
          Nutzungs- und Verwertungsrechte an einer Software, verstoßen.
          Verpfändungen oder Sicherungsübereignungen sind unzulässig. Die aus
          dem Weiterverkauf oder einem sonstigen Rechtsgrund (Versicherung,
          unerlaubte Handlung) bezüglich der Vorbehaltsware entstehenden
          Forderungen (einschließlich sämtlicher Saldoforderungen aus
          Kontokorrent) tritt der Kunde bereits jetzt sicherungshalber in vollem
          Umfang an FHW360 ab. FHW360 ermächtigt ihn widerruflich, die an den
          Verkäufer abgetretenen Forderungen für dessen Rechnung im eigenen
          Namen einzuziehen. Diese Einziehungsermächtigung kann nur widerrufen
          werden, wenn der Kunde seinen Zahlungsverpflichtungen nicht
          ordnungsgemäß nachkommt.
        </p>
        <p>
          (4) Bei Zugriffen Dritter auf die Vorbehaltsware, insbesondere
          Pfändungen, wird der Kunde auf das Eigentum von FHW360 hinweisen und
          FHW360 unverzüglich benachrichtigen, damit FHW360 die eigenen
          Eigentumsrechte durchsetzen kann. Soweit der Dritte nicht in der Lage
          ist, FHW360 die in diesem Zusammenhang entstehenden gerichtlichen oder
          außergerichtlichen Kosten zu erstatten, haftet hierfür der Kunde.
        </p>
        <h2 className={AGBHeadlineStyle}>
          § 6 Termine, Fristen und Leistungshindernisse
        </h2>
        <p>
          (1) Liefertermine oder -fristen, die verbindlich oder unverbindlich
          vereinbart werden können, bedürfen der Schriftform. Das Einhalten
          einer Leistungsfrist ist von der rechtzeitigen Selbstbelieferung
          abhängig.
        </p>
        <p>
          (2) Ist für die Leistung von FHW360 die Mitwirkung des Kunden
          erforderlich oder vereinbart, so verlängert sich die Lieferzeit um die
          Zeit, die der Kunde dieser Verpflichtung nicht nachgekommen ist,
          zuzüglich einer angemessenen Anlaufzeit für die Wiederaufnahme der
          Leistungserbringung durch FHW360.
        </p>
        <p>(3) Bei Verzögerungen infolge von</p>
        <p>a) Veränderungen der Anforderungen des Kunden,</p>
        <p>
          b) unzureichenden Voraussetzungen in der Anwendungsumgebung (Hardware-
          oder Softwaredefizite), soweit sie FHW360 nicht bekannt waren oder
          bekannt sein mussten,
        </p>
        <p>
          c) Problemen mit Produkten Dritter (z. B. Software anderer
          EDV-Hersteller), verlängert sich der Liefer- oder Leistungstermin
          entsprechend
        </p>
        <p>
          (4) Soweit FHW360 seine vertraglichen Leistungen infolge Arbeitskampf,
          höherer Gewalt oder anderer für FHW360 unabwendbarer Umstände nicht
          oder nicht fristgerecht erbringen kann, treten für FHW360 keine
          nachteiligen Rechtsfolgen ein.
        </p>
        <p>
          (5) Sofern die Verlängerung der Liefer- und Leistungsfristen länger
          als drei Monate dauert, sind beide Parteien berechtigt vom Vertrag
          zurückzutreten.
        </p>
        <p>
          (6) Werden von dem Kunden Änderungen oder Ergänzungen beauftragt, die
          nicht nur geringfügigen Umfang haben, so verlieren Termine und
          Fristen, die sich am ursprünglichen Vertragsgegenstand orientieren,
          ihre Gültigkeit.
        </p>
        <h2 className={AGBHeadlineStyle}>
          § 7 Zugangskennung und persönliches Passwort
        </h2>
        <p>
          (1) Für Online-Registrierungen sowie die Nutzung von Passwörtern
          gelten die folgenden Regelungen. Der Kunde hat
        </p>
        <p>
          1. bei gegebenenfalls erforderlichen Registrierungen und sonstigen zur
          Erreichung des Vertragszwecks erforderlichen Abfragen vollständige und
          wahrheitsgemäße Angaben zu machen,
        </p>
        <p>
          2. bei einer nachträglichen Änderung der abgefragten Daten diese
          unverzüglich in der dafür vorgesehenen Verwaltungsfunktion zu
          berichtigen,
        </p>
        <p>
          3. sicherzustellen, dass sein persönliches Passwort keinem Dritten
          zugänglich gemacht wird,
        </p>
        <p>
          4. jede Nutzung der Dienste von FHW360 unter der eigenen
          Zugangskennung durch Dritte zu unterbinden,
        </p>
        <p>
          5. die Nutzung automatischer Voreinstellungsfunktionen für das
          Passwort zu unterlassen,
        </p>
        <p>
          6. FHW360 unverzüglich mitzuteilen, wenn eine missbräuchliche
          Benutzung des Passworts bzw. des Benutzernamens vorliegt oder
          Anhaltspunkte für eine bevorstehende missbräuchliche Nutzung bestehen,
        </p>
        <p>
          7. sich nach jeder Nutzung eines Dienstes, welcher eine Anmeldung
          voraussetzt, durch einen Klick auf „Logout“ vom Dienst abzumelden.
        </p>
        <p>
          (2) Im Fall eines Pflichtverstoßes gemäß Absatz 1 ist FHW360
          berechtigt, nach seiner Wahl den Kunden vorübergehend vom Angebot von
          FHW360 auszuschließen und/oder ihm fristlos zu kündigen.
        </p>
        <p>
          (3) Der Kunde ist auch für Entgelte, die andere Personen befugt oder
          unbefugt über seine Zugangskennung verursachen, verantwortlich, es sei
          denn, der Kunde hat dies nicht zu vertreten. Sofern aufgrund einer
          technischen Überprüfung ausgeschlossen werden kann, dass ein Fehler
          der von FHW360 verwendeten Soft- und Hardware oder ein menschliches
          Versagen Seitens FHW360 vorliegt, wird ein Vertretenmüssen des Kunden
          vermutet.
        </p>
        <h2 className={AGBHeadlineStyle}>
          § 8 Art und Weise der Nutzung der Dienste von FHW360
        </h2>
        <p>
          (1) Der Kunde ist verpflichtet, die Dienste von FHW360 nicht
          rechtsmissbräuchlich oder in sittenwidriger Weise zu nutzen, den
          gesetzlichen Anforderungen zu genügen sowie die Rechte Dritter zu
          respektieren. Dies schließt insbesondere folgende Pflichten des Kunden
          ein:
        </p>
        <p>
          1. Der Kunde stellt sicher, dass durch von ihm in das Internet
          eingespeiste Daten nicht gegen die gesetzlichen Bestimmungen über den
          Jugendschutz, die Persönlichkeitsrechte sowie die Schutzrechte,
          insbesondere Marken-, Firmen- und Urheberrechte, Dritter verstoßen
          wird. Der Kunde unterlässt die Einspeisung von Daten mit
          sittenwidrigem Inhalt.
        </p>
        <p>
          2. Der Kunde ist verpflichtet, eine übermäßige Belastung der Netze
          durch ungezielte oder unsachgemäße Verbreitung von Daten zu
          unterlassen (z.B. Spamming).
        </p>
        <p>
          3. Der Kunde stellt sicher, dass seine auf dem Server von FHW360
          eingesetzten Skripts und Programme nicht mit Fehlern behaftet sind,
          die geeignet sind, die Leistungserbringung durch FHW360 zu stören.
        </p>
        <p>
          4. Der Kunde beachtet die gesetzlichen Vorgaben des Datenschutzes und
          der Datensicherheit.
        </p>
        <p>
          (2) Im Fall eines Pflichtverstoßes gemäß Absatz 1 ist FHW360
          berechtigt, nach seiner Wahl gegebenenfalls betroffene Inhalte mit
          sofortiger Wirkung vorübergehend zu sperren und/oder den Kunden
          vorübergehend vom Angebot von FHW360 auszuschließen und/oder ihm
          fristlos zu kündigen. Das gleiche gilt, wenn FHW360 von Dritten darauf
          hingewiesen wird, dass der Kunde unter Verstoß gegen die in Absatz 1
          enthaltenen Pflichten Inhalte vorhält oder verbreitet, sofern die
          Behauptung einer Rechtsverletzung nicht offensichtlich unrichtig ist
        </p>
        <h2 className={AGBHeadlineStyle}>
          § 9 Freistellung bei Schädigungen Dritter
        </h2>
        <p>
          (1) Der Kunde hat FHW360 den aus einer Pflichtverletzung
          resultierenden Schaden zu ersetzen. Der Kunde stellt FHW360 von allen
          Nachteilen frei, die FHW360 durch seine Inanspruchnahme durch Dritte
          wegen schädigender Handlungen des Kunden entstehen.
        </p>
        <p>
          (2) Der Kunde verpflichtet sich FHW360 von allen Schäden im
          Zusammenhang mit den vom Kunden gelieferten Daten und Inhalten
          freizustellen, soweit FHW360 wegen Urheberrechtsverletzungen oder
          Verletzungen von Persönlichkeitsrechten oder sonstigen Rechten Dritter
          in Anspruch genommen wird. Gleiches gilt hinsichtlich etwaiger
          Anwalts- und Gerichtskosten sowie aller Auslagen und Schäden, die
          FHW360 direkt oder indirekt durch eine solche Inanspruchnahme
          entstehen.
        </p>
        <p>
          (3) Der Kunde ist verpflichtet, FHW360 bei der Abwehr von Ansprüchen
          Dritter zu unterstützten, insbesondere durch Auskunft und Beibringung
          von Unterlagen.
        </p>
        <h2 className={AGBHeadlineStyle}>§ 10 Urheber- und Nutzungsrechte</h2>
        <p>
          (1) Für den Fall, dass der Kunde im Zusammenhang mit den Leistungen
          von FHW360 Inhalte bereithält oder übermittelt, an denen ihm
          Urheberrechte oder Nutzungsrechte zustehen, ist FHW360 für die Dauer
          der Leistungserbringung zu denjenigen Vervielfältigungshandlungen
          berechtigt, die FHW360 durchführen muss, um ihre vertraglichen
          Leistungen zu erbringen.
        </p>
        <p>
          (2) Die von FHW360 bereitgestellten Daten und Inhalte unterliegen
          urheberrechtlichem Schutz. Dem Kunden ist es daher nicht gestattet,
          diese Daten über die von FHW360 im Einzelfall gewährte Nutzung hinaus
          zu kopieren, zu bearbeiten und/oder weiterzuverbreiten.
        </p>
        <h2 className={AGBHeadlineStyle}>
          § 11 Datenschutz, Pflicht des Kunden zur Datensicherung, Geheimhaltung
        </h2>
        <p>
          (1) Die vom Nutzer zur Verfügung gestellten Daten werden von FHW360
          ausschließlich zu den sich aus diesem Vertrag ergebenden Zwecken unter
          Beachtung der einschlägigen gesetzlichen Bestimmungen des
          Datenschutzes gespeichert und verarbeitet und nicht an Dritte
          weitergegeben (siehe auch die Datenschutzerklärung von FHW360). Für
          die Einhaltung der Vorgaben des Datenschutzes durch die Kunden gilt §
          8 Absatz 1 Nr. 4.
        </p>
        <p>
          (3) Der Kunde ist verpflichtet, sich vor Datenverlust angemessen zu
          schützen. Da die Neuinstallation von Software, aber auch die
          Veränderung der installierten Software das Risiko eines Datenverlustes
          mit sich bringt, ist der Kunde verpflichtet, vor Neuinstallation oder
          Veränderung der installierten Software durch eine umfassende
          Datensicherung Vorsorge gegen Datenverlust zu treffen.
        </p>
        <p>
          (4) Die Vertragspartner verpflichten sich, alle ihnen vor oder bei der
          Vertragsdurchführung von dem jeweils anderen Vertragspartner
          zugehenden oder bekannt werdenden Gegenstände (z. B. Software,
          Unterlagen, Informationen), die rechtlich geschützt sind oder
          Geschäfts- oder Betriebsgeheimnisse beinhalten oder als vertraulich
          bezeichnet sind, auch über das Vertragsende hinaus vertraulich zu
          behandeln, es sei denn, sie sind ohne Verstoß gegen die
          Geheimhaltungspflicht öffentlich bekannt. Die Vertragspartner
          verwahren und sichern diese Gegenstände so, dass ein Zugang durch
          Dritte ausgeschlossen ist. Der Kunde macht die Vertragsgegenstände nur
          den Mitarbeitern und sonstigen Dritten zugänglich, die den Zugang zur
          Ausübung der ihnen eingeräumten Dienstaufgaben benötigen. Er belehrt
          diese Personen über die Geheimhaltungsbedürftigkeit der Gegenstände.
        </p>
        <h2 className={AGBHeadlineStyle}>§ 12 Haftung von FHW360</h2>
        <p>
          (1) Schadensersatzansprüche gegen FHW360 wegen des Ersatzes von
          Vermögensschäden sind auf Fälle vorsätzlichen oder grob fahrlässigen
          Handelns beschränkt. FHW360 haftet für einfache Fahrlässigkeit jedoch
          dann, wenn die Verletzung einer Pflicht vorliegt, deren ordnungsgemäße
          Erfüllung für die Erreichung des Vertragszwecks wesentlich ist
          (Kardinalpflicht). In diesem Fall ist die Haftung für Vermögensschäden
          hinsichtlich deren Umfangs auf den unmittelbaren Vermögensschaden und
          hinsichtlich deren Höhe auf den voraussehbaren Schaden begrenzt.
        </p>
        <p>
          (2) Die Haftung für Datenverlust ist durch den typischen
          Wiederherstellungsaufwand begrenzt. Dieser bemisst sich nach dem
          Schaden, der bei der Vornahme zumutbarer Sicherungsmaßnahmen (wie z.
          B. Anfertigung von Sicherungskopien) eingetreten wäre.
        </p>
        <p>
          (3) Soweit die Haftung von FHW360 ausgeschlossen oder beschränkt ist,
          gilt dies auch für die persönliche Haftung der Mitarbeiter, Vertreter
          und Erfüllungsgehilfen von FHW360.
        </p>
        <p>
          (4) Die Haftung nach den Vorschriften des Produkthaftungsgesetzes, die
          Haftung aus einer Garantie oder wegen eines Rechtsmangels sowie
          Ansprüche auf Ersatz von Körperschäden bleiben von diesen Regelungen
          unberührt.
        </p>
        <h2 className={AGBHeadlineStyle}>§ 13 Mitteilungen</h2>
        <p>
          (1) Soweit sich die Vertragspartner per elektronischer Post (E-Mail)
          verständigen, erkennen sie die unbeschränkte Wirksamkeit der auf
          diesem Wege übermittelten Willenserklärungen nach Maßgabe der
          folgenden Bestimmungen an.
        </p>
        <p>
          (2) Die E-Mail muss den Namen und die E-Mail-Adresse des Absenders,
          den Zeitpunkt der Absendung (Datum und Uhrzeit) sowie eine Wiedergabe
          des Namens des Absenders als Abschluss der Nachricht enthalten.
        </p>
        <p>
          (3) Eine im Rahmen der vorstehenden Bestimmungen zugegangene E-Mail
          gilt vorbehaltlich eines Gegenbeweises als vom anderen Partner
          stammend.
        </p>
        <p>
          (4) Die Verbindlichkeit der E-Mail und damit der Textform gilt für
          alle Erklärungen, welche die gewöhnliche Vertragsabwicklung mit sich
          bringt. Ausgeschlossen ist die Textform dagegen bei einer Kündigung
          sowie Erklärungen, die von einem Vertragspartner ausdrücklich
          abweichend von dieser Vereinbarung in schriftlicher Form verlangt
          werden.
        </p>
        <h2 className={AGBHeadlineStyle}>§ 14 Reseller</h2>
        <p>
          (1) FHW360 ist bereit, Lieferungen und Leistungen auch an solche
          Kunden zu erbringen, welche im eigenen Namen und auf eigene Rechnung
          die Lieferungen und Leistungen von FHW360 wiederum an deren Kunden
          („Endkunden“) weitergeben („Reseller“). Eine solche vollständige oder
          teilweise Weitergabe der Lieferungen und Leistungen von FHW360 bedarf
          der vorherigen Zustimmung von FHW360.
        </p>
        <p>
          (2) Der Reseller bleibt in diesen Fällen alleiniger Vertragspartner
          von FHW360. Er gewährleistet, dass der Endkunde ihm gegenüber in der
          Form verpflichtet wird, dass der Reseller seinerseits seine Pflichten
          und Obliegenheiten gegenüber FHW360 und sonstigen Beteiligten (z.B.
          DENIC) erfüllen kann.
        </p>
        <p>
          (3) Der Reseller ersetzt FHW360 alle Schäden und stellt FHW360 von
          allen Ansprüchen und sonstigen Beeinträchtigungen frei, die daraus
          entstehen, dass vorgenannte Regelungen nicht eingehalten werden oder
          der Endkunde seine Pflichten nicht erfüllt.
        </p>
        <h2 className={AGBHeadlineStyle}>
          § 15 Anwendbares Recht, Gerichtsstand
        </h2>
        <p>(1) Es gilt deutsches Recht unter Ausschluss des UN-Kaufrechts.</p>
        <p>
          (2) Erfüllungsort und Gerichtsstand für alle Streitigkeiten aus und im
          Zusammenhang mit dem diesen Allgemeinen Geschäftsbedingungen
          zugrundeliegendem Vertrag ist bei Verträgen mit Kaufleuten,
          juristischen Personen des öffentlichen Rechts oder
          öffentlich-rechtlichen Sondervermögen St. Goar.
        </p>
      </div>
    </div>
  </Layout>
)

export default AGB
